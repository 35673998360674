import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'

export const getResourceApiSlug = (
  type?: ShareResourceEnum,
): keyof typeof ShareResourceEnum | undefined => {
  switch (type) {
    case ShareResourceEnum['share-funnel']:
      return ShareResourceEnum.funnel
    case ShareResourceEnum['share-blog']:
      return ShareResourceEnum.blog
    case ShareResourceEnum['share-newsletter']:
      return ShareResourceEnum.newsletter
    case ShareResourceEnum['share-campaign']:
      return ShareResourceEnum.campaign
    case ShareResourceEnum['share-course']:
      return ShareResourceEnum.course
    //// Migrating enum
    default:
      return type
  }
}
