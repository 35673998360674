import InfoIcon from 'shared/icons/info-icon'
import { twMerge } from 'tailwind-merge'

interface SectionMessageProps {
  className?: string
  iconClassName?: string
  textClassName?: string
}

export const SectionMessage = ({
  children,
  className,
  iconClassName,
  textClassName,
}: React.PropsWithChildren<SectionMessageProps>) => (
  <div
    className={twMerge(
      `[&_a]:underline relative flex flex-wrap gap-1 items-center rounded-lg border p-2 border-danger bg-danger/10`,
      className,
    )}
  >
    <div className="flex gap-2 items-center">
      <InfoIcon className={twMerge('shrink-0', iconClassName)} />
      <span
        className={twMerge('flex gap-1 flex-wrap text-sm font-normal text-danger', textClassName)}
      >
        {children}
      </span>
    </div>
  </div>
)
