import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'
import { TLocoType } from 'shared/hooks/use-loco-translation'

export const getSuccessMessage = (t: TLocoType, type?: ShareResourceEnum) => {
  switch (type) {
    case ShareResourceEnum['share-funnel']:
      return t('dashboard.share.funnel.success')
    case ShareResourceEnum['share-blog']:
      return t('dashboard.share.blog.success')
    case ShareResourceEnum['share-newsletter']:
      return t('dashboard.share.newsletter.success')
    case ShareResourceEnum['share-campaign']:
      return t('dashboard.share.campaign.success')
    case ShareResourceEnum['share-course']:
      return t('dashboard.share.course.success')
    //// Migrating enum
    case ShareResourceEnum.funnel:
      return t('dashboard.share.funnel.success')
    case ShareResourceEnum.blog:
      return t('dashboard.share.blog.success')
    case ShareResourceEnum.newsletter:
      return t('dashboard.share.newsletter.success')
    case ShareResourceEnum.campaign:
      return t('dashboard.share.campaign.success')
    case ShareResourceEnum.course:
      return t('dashboard.share.course.success')
    default:
      return ''
  }
}
