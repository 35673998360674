export enum ShareResourceEnum {
  'share-funnel' = 'share-funnel',
  'share-blog' = 'share-blog',
  'share-newsletter' = 'share-newsletter',
  'share-campaign' = 'share-campaign',
  'share-course' = 'share-membership-course',
  //// Migrating enum
  funnel = 'funnel',
  blog = 'blog',
  newsletter = 'newsletter',
  campaign = 'campaign',
  course = 'course',
}
